

.navbar {
  .nav-item {
    a.nav-link {
      font-weight: 600;
      padding: 0.75rem;
      @media(min-width: 992px) {
        padding: 0.75rem !important;
      }

      color: #999999;
      &.active {
        color: white;
      }
    }
  }
  .navbar-nav {
    .nav-link {
      margin-left: 15px;
      color: white !important;
      border-bottom: transparent solid 3px;
    }
    .active {
      .nav-link {
        color: white !important;
        //color: #1e1e1e !important;
        border-bottom: white solid 3px;
      }
    }
  }
}

.navbar-light .navbar-toggler {
  color: white;
}

.navbar-brand {
  color: white !important;
  font-size: 1.6rem;
}
