$primary: #0084e1;

$active-color: $primary;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "~@bootstrap-extended-style/bootstrap-extended-style/index";

@import "~@fortawesome/fontawesome-pro/scss/fontawesome";
@import "~@fortawesome/fontawesome-pro/scss/brands";
@import "~@fortawesome/fontawesome-pro/scss/light";
@import "~@fortawesome/fontawesome-pro/scss/regular";
@import "~@fortawesome/fontawesome-pro/scss/solid";
@import "~@fortawesome/fontawesome-pro/scss/duotone";

@import "@shopcrate/shopcrate-framework/lib/scss/shopcrate";

@import "font";
@import "footer";
@import "navbar";

.jumbotron-hero {
  padding: 0;
  background-color: white;
  background-position: center;
  background-size: cover;
  background-image: url("../img/banner-small.jpg");

  .hero-mobile-fader {
    width: 100%;
    padding-top: 10vh;
    padding-bottom: 10vh;
    display: flex;
    align-content: center;

    background-color: rgba(0, 0, 0, 0.6);

    .container {
      vertical-align: middle;
    }
  }

  h1 {
    vertical-align: middle;
    font-size: 3.5rem;
    @media (max-width: 767.98px) {
      font-size: 2.5rem;
    }
  }
}

.jumbotron-header {
  //background-color: $primary;
  color: $primary;
  text-shadow: none;
}
